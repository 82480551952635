import { isEmpty, isObject } from "lodash/isEmpty";

import {
  LOGIN_LOADING,
  LOGIN_SUCCESSFUL,
  LOGIN_ERROR,
  LOGIN_LOGOUT,
} from "../constant/LoginConstant";

const iniState = {
  //LoginData: {},
  LoginLoading: false,
  LoginError: false,
  isLoggedIn: false,
  LoginErrorMsg: "",

  FPLoading: false,
  FPError: false,
  isFPIn: false,
  FPErrorMsg: "",
};

const LoginReducer = (state = iniState, action) => {
  switch (action.type) {
    case "FP_LOADING":
      //console.log("FP_LOADING");
      return {
        ...state,
        FPLoading: true,
        FPError: false,
        isFPIn: false,

        FPErrorMsg: "",
      };

    case "FP_SUCCESSFUL":
      //console.log("LOGIN_SUCCESSFUL", action.payload);
      return {
        ...state,
        FPLoading: false,
        FPError: false,
        isFPIn: true,
        FPData: action.payload,
        FPErrorMsg: "",
      };

    case 'FP_ERROR':
      //console.log("LOGIN_ERROR");
      return {
        ...state,
        FPLoading: false,
        FPError: true,
        isFPIn: false,
        FPData: "",
        FPErrorMsg: action.payload,
      };
    case LOGIN_LOADING:
      //console.log("LOGIN_LOADING");
      return {
        ...state,
        LoginLoading: true,
        LoginError: false,
        isLoggedIn: false,

        LoginErrorMsg: "",
      };

    case LOGIN_SUCCESSFUL:
      //console.log("LOGIN_SUCCESSFUL", action.payload);
      return {
        ...state,
        LoginLoading: false,
        LoginError: false,
        isLoggedIn: true,
        LoginData: action.payload,
        LoginErrorMsg: "",
      };
    case LOGIN_ERROR:
      //console.log("LOGIN_ERROR");
      return {
        ...state,
        LoginLoading: false,
        LoginError: true,
        isLoggedIn: false,
        LoginData: "",
        LoginErrorMsg: action.payload,
      };

    case LOGIN_LOGOUT:
      //console.log("LOGIN_LOGOUT");
      return {
        ...state,
        LoginLoading: false,
        LoginError: true,
        isLoggedIn: false,
        LoginData: "",
        LoginErrorMsg: action.payload,
      };

    default:
      return state;
  }
};

export default LoginReducer;
