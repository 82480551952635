export const CUSTOMER_LOADING = "CUSTOMER_LOADING";
export const CUSTOMER_SUCCESSFUL = "CUSTOMER_SUCCESSFUL";
export const CUSTOMER_ERROR = "CUSTOMER_ERROR";
export const CUSTOMER_SALES_INVOICE_SUCCESSFUL =
  "CUSTOMER_SALES_INVOICE_SUCCESSFUL";
export const CUSTOMER_ONLINE_PAYMENT_SUCCESSFUL =
  "CUSTOMER_ONLINE_PAYMENT_SUCCESSFUL";
export const CUSTOMER_PAYMENT_ENTRY_SUCCESSFUL =
  "CUSTOMER_PAYMENT_ENTRY_SUCCESSFUL";
export const CUSTOMER_SALES_INVOICE_SUCCESSFUL_LIMITED =
  "CUSTOMER_SALES_INVOICE_SUCCESSFUL_LIMITED";


  export const CUSTOMER_ONLINE_PAYMENT_SUCCESSFUL_LIMITED =
    "CUSTOMER_ONLINE_PAYMENT_SUCCESSFUL_LIMITED";


     export const CUSTOMER_PAYMENT_ENTRY_SUCCESSFUL_LIMITED =
       "CUSTOMER_PAYMENT_ENTRY_SUCCESSFUL_LIMITED";
    ;


