import axios from "axios";
//import store from "../store/storeMain";

/* import {
  setCurrentUser,
  removeCurrentUser,
} from "../store/actions/authActions"; */

//import api from "./config";

export default function setAuthorizationToken() {
  let base =
    localStorage.server === "live"
      ? process.env.REACT_APP_BASE_URL_LIVE
      : process.env.REACT_APP_BASE_URL_DEMO;
  
  console.log(base);

  axios.defaults.baseURL = base;
  axios.defaults.timeout = 18000;
  axios.defaults.timeoutErrorMessage = "Sever TimeOut Error";

  const errorHandler = (error) => {
    if (error) {
      let msg = "";
      if (error.response && error.response.status === 401) {
        msg = error.response.data.msg;
        //console.log(msg);
        console.log("UnAuthorization 401");
        document.location.href = "/#/session?msg=UnAuthorization 401";
        return;
      } else if (error.response && error.response.status === 403) {
        msg = error.response.data.msg;
        console.log(msg);
        console.log("Forbidden 403");
        document.location.href = "/#/session?msg=Forbidden 403";
        return;
      }
    } else {
      console.log(JSON.stringify(error));
    }
    return Promise.reject(error);
  };

  const responseHandler = (response) => {
    //console.log(response.status);
    if (response?.status === 401) {
      document.location.href = "/#/session?msg=unAunthorized 401";
    } else if (response?.status === 200) {
    } else if (response?.status === 403) {
      document.location.href = "/#/session?msg=Forbodden 403";
    }
    return response;
  };

  const requestHandler = async (request) => {
    /* request.headers.Authorization =
      "token " +
      localStorage.getItem("key") +
      ":" +
      localStorage.getItem("secret"); */

    let key =
      localStorage.server === "demo"
        ? process.env.REACT_APP_SERVICE_ACCOUNT_KEY_DEMO
        : process.env.REACT_APP_SERVICE_ACCOUNT_KEY_LIVE;

    let secret =
      localStorage.server === "demo"
        ? process.env.REACT_APP_SERVICE_ACCOUNT_SECREAT_DEMO
        : process.env.REACT_APP_SERVICE_ACCOUNT_SECREAT_LIVE;

    //console.log("TokenInter :: ", `${key}-${secret}`);

    request.headers.Authorization = "token " + key + ":" + secret;

    /* 
  request.headers.Authorization =
    "token " +
    process.env.REACT_APP_SERVICE_ACCOUNT_KEY +
    ":" +
    process.env.REACT_APP_SERVICE_ACCOUNT_SECREAT;
 */

    return request;
  };

  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      if (localStorage.getItem("token64")) {
        if (localStorage.getItem("Login") == "true") {
          //console.log("logged-in");
          //console.log(localStorage.getItem("key"));
          // console.log(localStorage.getItem("secret"));
          return requestHandler(config);
        }
        return config;
      }
      return config;
    },
    (error) => {
      //console.log(error);
      return errorHandler(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      return responseHandler(response);
    }, //,
    (error) => errorHandler(error)
  );
}
