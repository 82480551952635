//
import { combineReducers } from "redux";
import CustomerReducer from "./CustomerReducer";
import GlobalReducer from "./GlobalReducer";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import BookingReducer from "./BookingReducer";
import SidebarReducer from "./SidebarReducer";





const rootCombineReducer = combineReducers({
  CustomerFeed: CustomerReducer,
  GlobalFeed: GlobalReducer,
  LoginFeed: LoginReducer,
  UserFeed: UserReducer,
  BookingFeed: BookingReducer,
  SidebarFeed: SidebarReducer,
});


const rootReducer = (state, action) => {
  if (action.type === "DESTROY_SESSION") {
    // clear everything but keep the stuff we want to be preserved ..
    //delete state.something;
    //delete state.anotherThing;
    state = undefined;
  }
  return rootCombineReducer(state, action);
};



export default rootReducer;

