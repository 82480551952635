const initialState = {
  sidebarShow: "responsive",
 // sidebarShow: false,
};

const SidebarReducer = (state = initialState, { type, ...rest }) => {
 // console.log(JSON.stringify(type));

  switch (type) {
    case "set":
      return { ...state, ...rest };

    default:
      return state;
  }
};



export default SidebarReducer;
