import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import setAuthorizationToken from "./reusable/TokenInterceptor";

import { icons } from "./assets/icons";

import { Provider } from "react-redux";

import persist from "./store/store_index";
import { PersistGate } from "redux-persist/integration/react";
const persistStore = persist();

//git push https://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@github.com/octo5holdings/OEMAdmin.git

React.icons = icons;

setAuthorizationToken();

ReactDOM.render(
  <Provider store={persistStore.store}>
    <PersistGate loading={null} persistor={persistStore.persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

//https://coreui.io/vue/docs/components/icon.html
