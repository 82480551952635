import {
  CUSTOMER_LOADING,
  CUSTOMER_SUCCESSFUL,
  CUSTOMER_ERROR,
  CUSTOMER_SALES_INVOICE_SUCCESSFUL,
  CUSTOMER_ONLINE_PAYMENT_SUCCESSFUL,
  CUSTOMER_PAYMENT_ENTRY_SUCCESSFUL,
  CUSTOMER_SALES_INVOICE_SUCCESSFUL_LIMITED,
  CUSTOMER_ONLINE_PAYMENT_SUCCESSFUL_LIMITED,
  CUSTOMER_PAYMENT_ENTRY_SUCCESSFUL_LIMITED,
} from "../constant/CustomerConstant";

const iniState = {
  //CustomerData: {},
  CustomerLoading: false,
  CustomerError: true,
  CustomerErrorMsg: "",
  CustomerIsLoaded: false,
  
};

const CustomerReducer = (state = iniState, action) => {
  switch (action.type) {
    case CUSTOMER_LOADING:
      return {
        ...state,
        CustomerLoading: true,
        CustomerError: false,
        CustomerIsLoaded: false,
      };

    //CUSTOMER_ESTATE_SUCCESSFUL

    case 'CUSTOMER_ESTATE_SUCCESSFUL':
      return {
        ...state,
        CustomerLoading: false,
        CustomerError: false,
        CustomerIsLoaded: true,
        CustomerErrorMsg: null,
        CustomerEstateData: action.payload,
      };

    case CUSTOMER_SUCCESSFUL:
      return {
        ...state,
        CustomerLoading: false,
        CustomerError: false,
        CustomerIsLoaded: true,
        CustomerErrorMsg: null,
        CustomerData: action.payload,
      };

    //

    case CUSTOMER_SALES_INVOICE_SUCCESSFUL_LIMITED:
      return {
        ...state,
        CustomerLoading: false,
        CustomerError: false,
        CustomerIsLoaded: true,
        CustomerErrorMsg: null,
        CustomerSILimit: action.payload,
      };

    case CUSTOMER_SALES_INVOICE_SUCCESSFUL:
      return {
        ...state,
        CustomerLoading: false,
        CustomerError: false,
        CustomerIsLoaded: true,
        CustomerErrorMsg: null,
        CustomerSI: action.payload,
      };

    //
    case CUSTOMER_ONLINE_PAYMENT_SUCCESSFUL_LIMITED:
      return {
        ...state,
        CustomerLoading: false,
        CustomerError: false,
        CustomerIsLoaded: true,
        CustomerErrorMsg: null,
        CustomerOPLimited: action.payload,
      };

    case CUSTOMER_ONLINE_PAYMENT_SUCCESSFUL:
      return {
        ...state,
        CustomerLoading: false,
        CustomerError: false,
        CustomerIsLoaded: true,
        CustomerErrorMsg: null,
        CustomerOP: action.payload,
      };

    case CUSTOMER_PAYMENT_ENTRY_SUCCESSFUL:
     // alert(action.payload);
      //console.log(action.payload);
      return {
        ...state,
        CustomerLoading: false,
        CustomerError: false,
        CustomerIsLoaded: true,
        CustomerErrorMsg: null,
        CustomerPE: action.payload,
      };

    case CUSTOMER_PAYMENT_ENTRY_SUCCESSFUL_LIMITED:
      return {
        ...state,
        CustomerLoading: false,
        CustomerError: false,
        CustomerIsLoaded: true,
        CustomerErrorMsg: null,
        CustomerPELimited: action.payload,
      };

    //

    case CUSTOMER_ERROR:
      return {
        ...state,
        CustomerLoading: false,
        CustomerError: true,
        CustomerIsLoaded: false,
        CustomerErrorMsg: action.payload,
      };

    default:
      return state;
  }
};

export default CustomerReducer;
