import {
  GLOBAL_LOADING,
  GLOBAL_SUCCESSFUL,
  GLOBAL_SUCCESSFUL_SAVED,
  GLOBAL_ERROR,
  GLOBAL_WARNING,
  GLOBAL_TIMEOUT,
} from "../constant/GlobalConstant";

const iniState = {
  //GlobalData: {},
  GlobalLoading: false,
  GlobalError: false,
  GlobalIsSaved: false,
  GlobalWarning: false,

  GlobalTitle: "",

  GlobalIsSavedTitle: "",
  GlobalIsSavedBody: "",
  
  GlobalErrorMsg: "",
  GlobalErrorSource: "",
  GlobalErrorTitle: "",
  GlobalErrorBody: "",

  GlobalWarningMsg: "",
  GlobalWarningSource: "",
  GlobalWarningTitle: "",
  GlobalWarningBody: "",
};

const GlobalReducer = (state = iniState, action) => {
  switch (action.type) {
    case "GLOBAL_INITIAL_STATE":
      //console.log("GLOBAL_LOADING");
      return {
        ...state,
        GlobalLoading: false,
        GlobalError: false,
        GlobalWarning: false,
        GlobalIsSaved: false,

        GlobalTitle: "",

        GlobalIsSavedTitle: "",
        GlobalIsSavedBody: "",
        GlobalErrorMsg: "",

        GlobalErrorSource: "",
        GlobalErrorTitle: "",
        GlobalErrorBody: "",

        GlobalWarningMsg: "",
        GlobalWarningSource: "",
        GlobalWarningTitle: "",
        GlobalWarningBody: "",
      };

    case GLOBAL_LOADING:
      //console.log("GLOBAL_LOADING");
      return {
        ...state,
        GlobalLoading: true,
        GlobalError: false,
        GlobalWarning: false,
        GlobalTitle: action.title,
        GlobalIsSaved: false,
        GlobalIsSavedTitle: "",
        GlobalIsSavedBody: "",

        GlobalErrorMsg: "",
        GlobalErrorSource: "",
        GlobalErrorTitle: "",
        GlobalErrorBody: "",

        GlobalWarningMsg: "",
        GlobalWarningSource: "",
        GlobalWarningTitle: "",
        GlobalWarningBody: "",
      };

    case GLOBAL_SUCCESSFUL:
      //console.log("GLOBAL_SUCCESSFUL");
      return {
        ...state,
        GlobalLoading: false,
        GlobalTitle: "",
        GlobalError: false,
        GlobalWarning: false,
        GlobalErrorMsg: "",
        GlobalWarningMsg: "",
      };

    case GLOBAL_SUCCESSFUL_SAVED:
      //console.log("GLOBAL_SUCCESSFUL");
      return {
        ...state,
        GlobalLoading: false,
        GlobalError: false,
        GlobalWarning: false,
        GlobalIsSaved: true,
        GlobalTitle: "",
        GlobalIsSavedTitle: action.title,
        GlobalIsSavedBody: action.body,
        GlobalErrorMsg: "",
        GlobalWarningMsg: "",
      };
    case GLOBAL_ERROR:
      //console.log(action);
      return {
        ...state,
        GlobalLoading: false,
        GlobalError: true,
        GlobalIsSaved: false,
        GlobalTitle: "",
        GlobalIsSavedTitle: "",
        GlobalIsSavedBody: "",

        GlobalErrorMsg: action.payload,
        GlobalErrorSource: action.source,
        GlobalErrorTitle: action.title,
        GlobalErrorBody: action.body,
      };

    case GLOBAL_WARNING:
      //console.log(action);
      return {
        ...state,
        GlobalLoading: false,
        GlobalWarning: true,
        GlobalIsSaved: false,
        GlobalTitle: "",
        GlobalIsSavedTitle: "",
        GlobalIsSavedBody: "",

        GlobalWarningMsg: action.payload,
        GlobalWarningSource: action.source,
        GlobalWarningTitle: action.title,
        GlobalWarningBody: action.body,
      };

    case GLOBAL_TIMEOUT:
      //console.log(action.payload);
      return {
        ...state,
        GlobalLoading: false,
        GlobalError: true,
        GlobalWarning: false,
        GlobalErrorMsg: action.payload,
      };

    default:
      return state;
  }
};

export default GlobalReducer;
