export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_LOGOUT = "LOGIN_LOGOUT";


export const SIGNUP_LOADING = "SIGNUP_LOADING";
export const SIGNUP_SUCCESSFUL = "SIGNUP_SUCCESSFUL";
export const SIGNUP_ERROR = "SIGNUP_ERROR";



