import {
  BOOKING_LOADING,
  BOOKING_SAVING_LOADING,
  BOOKING_SUCCESSFUL,
  BOOKING_VALIDATE_SUCCESSFUL,
  BOOKING_ERROR,
  EVENT_BOOKING_LOADING,
  EVENT_BOOKING_SAVING_LOADING,
  EVENT_BOOKING_SUCCESSFUL,
  EVENT_BOOKING_VALIDATE_SUCCESSFUL,
  EVENT_BOOKING_ERROR,
} from "../constant/BookingConstant";

const iniState = {
  //BookingData: {},
  BookingLoading: false,
  BookingError: false,
  BookingErrorMsg: "",
  BookingIsLoaded: false,

  EvBookingLoading: false,
  EvBookingError: false,
  EvBookingErrorMsg: "",
  EvBookingIsLoaded: false,

  ESearchBookingLoading: true,
  ESearchBookingError: false,
  ESearchBookingErrorMsg: "",
  ESearchBookingData:{}
};

const BookingReducer = (state = iniState, action) => {
  switch (action.type) {
    case "BOOKING_INITIAL":
      return {
        ...state,
        BookingVData: {},
        BookingLoading: false,
        BookingSavingLoading: false,
        BookingError: false,
        BookingErrorMsg: "",
        BookingIsLoaded: false,
      };

    case BOOKING_LOADING:
      return {
        ...state,
        BookingLoading: true,
        BookingError: false,
        BookingIsLoaded: false,
      };

    case BOOKING_SAVING_LOADING:
      return {
        ...state,
        BookingSavingLoading: true,
      };

    case BOOKING_SUCCESSFUL:
      return {
        ...state,
        BookingLoading: false,
        BookingSavingLoading: false,

        BookingError: false,
        BookingIsLoaded: true,
        BookingErrorMsg: null,
        BookingData: action.payload,
      };

    case BOOKING_VALIDATE_SUCCESSFUL:
      return {
        ...state,
        BookingLoading: false,
        BookingSavingLoading: false,

        BookingError: false,
        BookingIsLoaded: true,
        BookingErrorMsg: null,
        BookingVData: action.payload,
      };

    case BOOKING_ERROR:
      return {
        ...state,
        BookingLoading: false,
        BookingSavingLoading: false,

        BookingError: true,
        BookingIsLoaded: false,
        BookingErrorMsg: action.payload,
      };

    ////////////////////////////////////////////////////////////////////////////////////

    case "EVENT_BOOKING_INITIAL":
      return {
        ...state,
        EvBookingVData: {},
        EvBookingLoading: false,
        EvBookingSavingLoading: false,
        EvBookingError: false,
        EvBookingErrorMsg: "",
        EvBookingIsLoaded: false,
      };

    case EVENT_BOOKING_LOADING:
      return {
        ...state,
        EvBookingLoading: true,
        EvBookingError: false,
        EvBookingIsLoaded: false,
      };

    case EVENT_BOOKING_SAVING_LOADING:
      return {
        ...state,
        EvBookingSavingLoading: true,
      };

    case EVENT_BOOKING_SUCCESSFUL:
      return {
        ...state,
        EvBookingLoading: false,
        EvBookingSavingLoading: false,

        EvBookingError: false,
        EvBookingIsLoaded: true,
        EvBookingErrorMsg: null,
        EvBookingData: action.payload,
      };

    case EVENT_BOOKING_VALIDATE_SUCCESSFUL:
      return {
        ...state,
        EvBookingLoading: false,
        EvBookingSavingLoading: false,

        EvBookingError: false,
        EvBookingIsLoaded: true,
        EvBookingErrorMsg: null,
        EvBookingVData: action.payload,
      };

    case EVENT_BOOKING_ERROR:
      return {
        ...state,
        EvBookingLoading: false,
        EvBookingSavingLoading: false,

        EvBookingError: true,
        EvBookingIsLoaded: false,
        EvBookingErrorMsg: action.payload,
      };

    case "EVENT_BOOKING_SEARCH_LOADING":
      return {
        ...state,
        ESearchBookingLoading: true,
        ESearchBookingError: false,
        ESearchBookingErrorMsg: "",
      };

    case "EVENT_BOOKING_SEARCH_SUCCESSFUL":
      return {
        ...state,
        ESearchBookingLoading: false,
        ESearchBookingError: false,
        ESearchBookingErrorMsg: "",
        ESearchBookingData: action.payload,
      };

    case 'EVENT_BOOKING_SEARCH_ERROR':
      return {
        ...state,
        ESearchBookingLoading: true,
        ESearchBookingError: true,
        ESearchBookingErrorMsg: action.payload,
      };

    default:
      return state;
  }
};

export default BookingReducer;
