export const USER_LOADING = "USER_LOADING";
export const USER_SUCCESSFUL = "USER_SUCCESSFUL";
export const USER_ERROR = "USER_ERROR";
export const USER_SAVED_SUCCESSFUL = "USER_SAVED_SUCCESSFUL";
export const USER_PROFILE_SUCCESSFUL = "USER_PROFILE_SUCCESSFUL";
export const USER_SAVED_ERP_SUCCESSFUL = "USER_SAVED_ERP_SUCCESSFUL";




export const DS_LOADING = "DS_LOADING";

export const DS_SINGLE_LOADING = "DS_SINGLE_LOADING";

export const DS_SUCCESSFUL = "DS_SUCCESSFUL";
export const DS_ERROR = "DS_ERROR";
export const DS_SAVED_SUCCESSFUL = "DS_SAVED_SUCCESSFUL";
export const DS_PROFILE_SUCCESSFUL = "DS_PROFILE_SUCCESSFUL";



export const EV_SUCCESSFUL = "EV_SUCCESSFUL";
export const EV_LOADING = "EV_LOADING";
export const EV_ERROR = "EV_ERROR";
export const EV_SAVED_SUCCESSFUL = "EV_SAVED_SUCCESSFUL";
export const EV_PROFILE_SUCCESSFUL = "EV_PROFILE_SUCCESSFUL";
export const EV_SINGLE_SUCCESSFUL = "EV_SINGLE_SUCCESSFUL";
export const EV_ALL_SUCCESSFUL = "EV_ALL_SUCCESSFUL";




export const DS_SAVED_ERP_SUCCESSFUL = "DS_SAVED_ERP_SUCCESSFUL";
export const DS_SINGLE_SUCCESSFUL = "DS_SINGLE_SUCCESSFUL";



export const DEVICE_LOADING = "DEVICE_LOADING";
export const DEVICE_SUCCESSFUL = "DEVICE_SUCCESSFUL";
export const DEVICE_ERROR = "DEVICE_ERROR";


//DEVICE_SUCCESSFUL


