export const BOOKING_LOADING = "BOOKING_LOADING";
export const BOOKING_SAVING_LOADING = "BOOKING_SAVING_LOADING";
export const BOOKING_SUCCESSFUL = "BOOKING_SUCCESSFUL";
export const BOOKING_ERROR = "BOOKING_ERROR";
export const BOOKING_VALIDATE_SUCCESSFUL = "BOOKING_VALIDATE_SUCCESSFUL";


export const EVENT_BOOKING_LOADING = "EVENT_BOOKING_LOADING";
export const EVENT_BOOKING_SAVING_LOADING = "EVENT_BOOKING_SAVING_LOADING";
export const EVENT_BOOKING_SUCCESSFUL = "EVENT_BOOKING_SUCCESSFUL";
export const EVENT_BOOKING_ERROR = "EVENT_BOOKING_ERROR";
export const EVENT_BOOKING_VALIDATE_SUCCESSFUL = "EVENT_BOOKING_VALIDATE_SUCCESSFUL";




//

