import {
  USER_LOADING,
  USER_SUCCESSFUL,
  USER_SAVED_SUCCESSFUL,
  USER_ERROR,
  USER_PROFILE_SUCCESSFUL,
  USER_SAVED_ERP_SUCCESSFUL,
  DS_LOADING,
  DS_SINGLE_LOADING,
  DS_SUCCESSFUL,

  EV_LOADING,
  EV_SINGLE_LOADING,
  EV_SUCCESSFUL,
  EV_SINGLE_SUCCESSFUL,
  EV_ALL_SUCCESSFUL,


  DS_ERROR,
  DS_SAVED_SUCCESSFUL,
  DS_PROFILE_SUCCESSFUL,
  DS_SAVED_ERP_SUCCESSFUL,
  DS_SINGLE_SUCCESSFUL,
  DEVICE_LOADING,
  DEVICE_SUCCESSFUL,
  DEVICE_ERROR,
} from "../constant/UserConstant";

const iniState = {
  PushSegLoading: false,
  //DeviceData: {},
  //PushSegData:{},
  PushSegErrorMsg: "",

  DeviceLoading: false,
  //DeviceData: {},
  //PushSegmentData:{},
  DeviceErrorMsg: "",
  //UserData: {},
  DSBookLoading: false,
  //DSBookData: {},

  UserLoading: false,
  UserError: true,
  UserErrorMsg: "",
  UserIsLoaded: false,
  UserSaved: false,
  UserSavedErp: false,

  DStaffLoading: false,
  DS_SingleStaffLoading: false,
  DStaffError: true,
  DStaffErrorMsg: "",
  DStaffIsLoaded: false,
  DStaffSaved: false,
  DStaffSavedErp: false,
  DStaffSingleData: {},

  DStaffCheckedData: null,
  DSisCheckIn: false,
  DSisCheckOut: false,

  DStaffSingleCheckedData: [],

  EVLoading: false,
  EVError: true,
  EVErrorMsg: "",
  EVIsLoaded: false,
  EVSaved: false,
  EVSavedErp: false,
  EVSingleData: {},
  EVSingleCheckedData: [],

  //////////////////// SITE WORKER PROPS

  SWorkerLoading: false,
  SW_SingleStaffLoading: false,
  SWorkerError: true,
  SWorkerErrorMsg: "",
  SWorkerIsLoaded: false,
  SWorkerSaved: false,
  SWorkerSavedErp: false,
  SWorkerSingleData: {},

  SWorkerCheckedData: null,
  SWisCheckIn: false,
  SWisCheckOut: false,
  //SWorkerData:[],

  SWorkerSingleCheckedData: [],
};

const UserReducer = (state = iniState, action) => {
  switch (action.type) {
    case "DSBOOK_LOADER":
      return { DSBookLoading: true };

    case "DSBOOK_SUCCESS":
      return {
        DSBookLoading: false,
        DSBookData: action.payload,
      };

    //PUSH_SEGMENT_SUCCESSFUL
    case USER_LOADING:
      // console.log("USER_LOADING");
      return {
        ...state,
        UserLoading: true,
        UserError: false,
        UserIsLoaded: false,
        UserSaved: false,
        UserSavedErp: false,
      };

    case DS_SINGLE_LOADING:
      //console.log("DS_LOADING");
      return {
        ...state,
        DS_SingleStaffLoading: true,
        DStaffError: false,
        DStaffIsLoaded: false,
        DStaffSaved: false,
        DStaffSavedErp: false,
      };

    case DS_LOADING:
      //console.log("DS_LOADING");
      return {
        ...state,
        DStaffLoading: true,
        DStaffError: false,
        DStaffIsLoaded: false,
        DStaffSaved: false,
        DStaffSavedErp: false,
      };

    case USER_SUCCESSFUL:
      //console.log("USER_SUCCESSFUL");
      return {
        ...state,
        UserLoading: false,
        UserError: false,
        UserIsLoaded: true,
        UserErrorMsg: null,
        UserData: action.payload,
      };

    case DEVICE_LOADING:
      return {
        ...state,
        DeviceLoading: true,
        DeviceErrorMsg: null,
      };

    case "PUSH_SEGMENT_LOADING":
      return {
        ...state,
        PushSegLoading: true,
        PushSegErrorMsg: null,
      };

    case "PUSH_SEGMENT_SUCCESSFUL":
      return {
        ...state,
        PushSegLoading: false,
        PushSegData: action.payload,
        PushSegErrorMsg: null,
      };

    case DEVICE_SUCCESSFUL:
      return {
        ...state,
        DeviceLoading: false,
        DeviceData: action.payload,
        DeviceErrorMsg: null,
      };
    case DS_SINGLE_SUCCESSFUL:
      return {
        ...state,
        DS_SingleStaffLoading: false,
        DStaffError: false,
        DStaffIsLoaded: true,
        DStaffErrorMsg: null,
        DStaffSingleData: action.payload,
      };

    case "DS_SINGLE_ERROR":
      return {
        ...state,
        DS_SingleStaffLoading: false,
        DStaffError: true,
        DStaffIsLoaded: false,
        DStaffErrorMsg: action.payload,
        DStaffSavedErp: true,
      };

    case "SW_SINGLE_SUCCESSFUL":
      return {
        ...state,
        SW_SingleStaffLoading: false,
        SWorkerError: false,
        SWorkerIsLoaded: true,
        SWorkerErrorMsg: null,
        SWorkerSingleData: action.payload,
      };

    case "SW_CHECKIN_CHECKOUT":
      return {
        ...state,
        SWorkerCheckedData: action.payload,
        SWisCheckIn: action.IsCheckIn,
        SWisCheckOut: action.IsCheckOut,
      };

    case "DS_CHECKIN_CHECKOUT":
      return {
        ...state,
        DStaffCheckedData: action.payload,
        DSisCheckIn: action.IsCheckIn,
        DSisCheckOut: action.IsCheckOut,
      };

    case "DS_SINGLE_CHECKIN_CHECKOUT":
      return {
        ...state,
        DStaffSingleCheckedData: action.payload,
        // DSisCheckIn: action.IsCheckIn,
        //DSisCheckOut: action.IsCheckOut,
      };

    case "SW_SINGLE_CHECKIN_CHECKOUT":
      return {
        ...state,
        SWorkerSingleCheckedData: action.payload,
        // DSisCheckIn: action.IsCheckIn,
        //DSisCheckOut: action.IsCheckOut,
      };

    //

    case EV_LOADING:
      //console.log("DS_LOADING");
      return {
        ...state,
        EVLoading: true,
        EVError: false,
        EVIsLoaded: false,
        EVSaved: false,
        EVSavedErp: false,
      };

    case EV_SUCCESSFUL:
      return {
        ...state,
        EVLoading: false,
        EVError: false,
        EVIsLoaded: true,
        EVErrorMsg: null,
        EVData: action.payload,
      };
    //
    case EV_SINGLE_SUCCESSFUL:
      return {
        ...state,
        EVSingleCheckedData: action.payload,
        // DSisCheckIn: action.IsCheckIn,
        //DSisCheckOut: action.IsCheckOut,
      };

    case EV_ALL_SUCCESSFUL:
      return {
        ...state,
        EVAllCheckedData: action.payload,
        // DSisCheckIn: action.IsCheckIn,
        //DSisCheckOut: action.IsCheckOut,
      };

    case DS_SUCCESSFUL:
      return {
        ...state,
        DStaffLoading: false,
        DStaffError: false,
        DStaffIsLoaded: true,
        DStaffErrorMsg: null,
        DStaffData: action.payload,
      };

    case "SW_SUCCESSFUL":
      //alert("SW_SUCCESSFUL");
      //console.log(action.payload);
      return {
        ...state,
        SWorkerLoading: false,
        SWorkerError: false,
        SWorkerIsLoaded: true,
        SWorkerErrorMsg: null,
        SWorkerData: action.payload,
      };

    //

    case "DS_BOOKING_SUCCESSFUL":
      return {
        ...state,
        DStaffLoading: false,
        DStaffError: false,
        DStaffIsLoaded: true,
        DStaffErrorMsg: null,
        DStaffBookingData: action.payload,
      };

    case "SW_BOOKING_SUCCESSFUL":
      return {
        ...state,
        SWorkerLoading: false,
        SWorkerError: false,
        SWorkerIsLoaded: true,
        SWorkerErrorMsg: null,
        SWorkerBookingData: action.payload,
      };

    case USER_PROFILE_SUCCESSFUL:
      return {
        ...state,
        UserLoading: false,
        UserError: false,
        UserIsLoaded: true,
        UserErrorMsg: null,
        UserProfileData: action.payload,
      };

    //

    case USER_SAVED_ERP_SUCCESSFUL:
      console.log("USER_SAVED_ERP_SUCCESSFUL");
      return {
        ...state,
        UserLoading: false,
        UserError: false,
        UserIsLoaded: true,
        UserErrorMsg: null,
        UserSavedErp: true,
        //UserData: action.payload,
      };

    case USER_SAVED_SUCCESSFUL:
      console.log("USER_SAVED_SUCCESSFUL");
      return {
        ...state,
        UserLoading: false,
        UserError: false,
        UserIsLoaded: true,
        UserErrorMsg: null,
        UserSaved: true,
        UserData: action.payload,
      };

    case USER_ERROR:
      console.log("USER_ERROR");
      return {
        ...state,
        UserLoading: false,
        UserError: true,
        UserIsLoaded: false,
        UserSaved: false,
        UserErrorMsg: action.payload,
      };

    default:
      return state;
  }
};

export default UserReducer;
